<template>
  <div class="py-sm-10 py5">
    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <v-card class="v-card--no-border mb-5">
          <h1 class="font-weight-bold mb-5 text-center">Company name</h1>

          <p class="text-center">
            Status: <v-chip small color="success"> In progress </v-chip>
            <br />
          </p>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <OnboardCompanyStepper />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
} from '@mdi/js';

import { ref, getCurrentInstance, onMounted, computed, watch } from '@vue/composition-api';
import OnboardCompanyStepper from '@/components/onboard-company/Stepper.vue';

export default {
  name: 'GlobalAdminOnboard',
  components: { OnboardCompanyStepper },
  setup() {
    const vm = getCurrentInstance().proxy;

    return {
      // IntegrationOptions,
      // filterByIntegration,

      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
      },
    };
  },
};
</script>

<style scoped></style>
